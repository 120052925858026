import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageSelectButton, FooterWrapper } from './footer.styled';
import { Grid, Divider, Menu, MenuItem, Typography } from '@mui/material';
import { IconArrowDropDown, IconArrowDropUp, IconLanguage } from '@mzc-cloudplex/icons';
import { Button } from '@mzc-cloudplex/core';
import Cookies from 'js-cookie';
import theme from '@/styles/theme';
import { useLocaleProvider } from '@packages/cp-ui';
import config from '@/config';

const Footer = () => {
  const isLocal = config.env.toLowerCase() === 'local';

  const { locales, get } = useLocaleProvider();
  const { i18n, t } = useTranslation('common', { keyPrefix: 'label' });
  const [showLanguageLayer, setShowLanguageLayer] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const closeLanguageLayer = useCallback(() => {
    if (showLanguageLayer === true) {
      setShowLanguageLayer(false);
    }
  }, [showLanguageLayer]);

  const onChangeLanguage = async (locale) => {
    await i18n.changeLanguage(locale);
    Cookies.set('i18next', locale, {
      expires: 1,
      path: '/',
      domain: isLocal ? 'localhost' : 'cloudplex.so'
    });
    closeLanguageLayer();
    setAnchorEl(null);
  };

  const openTermsPrivacy = useCallback(
    () => window.open(`https://cloudplex.megazone.io/private_policy.html`),
    []
  );

  const openTermsService = useCallback(
    () => window.open(`https://cloudplex.megazone.io/terms_conditions.html`),
    []
  );

  const openCustomerService = useCallback(() => window.open(`https://help.megazone.io/`), []);

  const openRoadmap = useCallback(
    () =>
      window.open(`https://portal.productboard.com/megazone/5-cloudplex-delivery/tabs/21-released`),
    []
  );

  return (
    <FooterWrapper
      container
      direction={`row`}
      justifyContent={`space-between`}
      alignItems={`center`}
      flexWrap={`nowrap`}
    >
      <Divider sx={{ margin: `0`, width: `auto`, height: `0` }} />
      <Grid item>
        <Grid container direction={`row`} alignItems={`center`}>
          <Grid item>
            <Button
              type={`button`}
              size={`small`}
              variant={`text`}
              color={'secondary'}
              onClick={openTermsService}
            >
              {t(`termsConditions`)}
            </Button>
          </Grid>
          <Grid item>
            <Divider orientation='vertical' flexItem />
          </Grid>
          <Grid item>
            <Button
              className={'privacy'}
              type={`button`}
              size={`small`}
              variant={`text`}
              color={'grayscaleSecondary'}
              onClick={openTermsPrivacy}
              sx={{ fontWeight: 500 }}
            >
              {t(`privacyPolicy`)}
            </Button>
          </Grid>
          <Grid item className={'divider'}>
            <Divider orientation='vertical' flexItem />
          </Grid>
          <Grid item className={'address'}>
            <address>
              ©{' '}
              <Typography component={`span`} variant={'body2'} color={theme.palette.primary.main}>
                MegazoneCloud Corp.
              </Typography>{' '}
              All rights reserved.
            </address>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction={`row`} alignItems={`center`} flexWrap={`nowrap`}>
          <Grid item>
            <Button
              type={`button`}
              size={`small`}
              variant={`text`}
              color={'secondary'}
              onClick={openCustomerService}
            >
              {t(`customerService`)}
            </Button>
          </Grid>
          <Grid item>
            <Divider orientation='vertical' flexItem />
          </Grid>
          <Grid item>
            <Button
              type={`button`}
              size={`small`}
              variant={`text`}
              color={'secondary'}
              onClick={openRoadmap}
            >
              {t(`roadmap`)}
            </Button>
          </Grid>
          <Grid item>
            <Divider orientation='vertical' flexItem />
          </Grid>
          <LanguageSelectButton onClick={handleClick} active={open}>
            <IconLanguage size={16} />
            <strong>{get(i18n.language)?.name}</strong>
            {open ? <IconArrowDropDown size={25} /> : <IconArrowDropUp size={25} />}
          </LanguageSelectButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            slotProps={{
              paper: { style: { width: '150px' } }
            }}
          >
            {locales.map((item) => (
              <MenuItem
                key={item.locale}
                onClick={() => onChangeLanguage(item.locale)}
                selected={i18n.language === item.locale}
              >
                {t(`${item.label}`)}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </Grid>
    </FooterWrapper>
  );
};
export default Footer;
