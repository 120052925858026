import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell, TableRow } from '@mzc-cloudplex/core';
import { Box, Typography, Skeleton } from '@mui/material';
import { useQueryToGetCompareJobTaskV4 } from '@/hooks/queries/useQueryToGetCompareJobTaskV4';
import theme from '@/styles/theme';
import CdnIconName from '@/components/common/CdnIconName';
import { IconErrorCircle, IconPath } from '@mzc-cloudplex/icons';
import moment from 'moment/moment';
import TaskItemSkeleton from '@/components/manage/job/common/TaskItemSkeleton';
import CompareTaskResult from '@/components/manage/job/common/compare/CompareTaskResult';

const CompareJobTaskItem = ({ spaceId, projectId, jobId, taskId, onItemClick, onChangeTask }) => {
  const { t } = useTranslation('jobs');

  const {
    data: dataToGetCompareJobTask,
    isLoading: isLoadingToGetCompareJobTask,
    isError: isErrorToGetCompareJobTask
  } = useQueryToGetCompareJobTaskV4({
    spaceId: spaceId,
    projectId: projectId,
    jobId: jobId,
    taskId: taskId
  });

  useEffect(() => {
    if (dataToGetCompareJobTask != null) onChangeTask(dataToGetCompareJobTask);
  }, [dataToGetCompareJobTask]);

  if (isLoadingToGetCompareJobTask) return <TaskItemSkeleton />;

  return (
    <TableRow sx={{ cursor: 'pointer' }} onClick={() => onItemClick?.(dataToGetCompareJobTask)}>
      <TableCell align={'left'}>
        <Box display={'inline-flex'} alignItems={'center'} gap={1}>
          <IconPath size={16} htmlColor={theme.palette.grayscaleSecondary.light} />
          <Typography>{dataToGetCompareJobTask.filePath}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <CdnIconName isNameVisible service={dataToGetCompareJobTask.cdn.service} size={'small'} />
      </TableCell>
      <TableCell>
        {isLoadingToGetCompareJobTask ? (
          <Skeleton variant={'text'} width={100} height={16} />
        ) : isErrorToGetCompareJobTask ? (
          <Box alignItems={'center'} gap={1}>
            <IconErrorCircle size={12} color={'error'} />
            <Typography color={'error'}>{t('error')}</Typography>
          </Box>
        ) : (
          <CompareTaskResult
            status={dataToGetCompareJobTask?.status}
            result={dataToGetCompareJobTask?.result}
          />
        )}
      </TableCell>
      <TableCell>
        <Typography>
          {moment.utc(dataToGetCompareJobTask.startedAt).local().format('YYYY-MM-DD HH:mm:ss')}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default CompareJobTaskItem;
