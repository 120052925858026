import React from 'react';
import { IconArrowBack, IconArrowForward, IconRefresh } from '@mzc-cloudplex/icons';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { IconButton, Tooltip } from '@mzc-cloudplex/core';
import theme from '@/styles/theme';

const Pagination = ({ disabled, page, pageSize, onChange, totalCount, refresh }) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      display={'inline-flex'}
      justifyContent={'flex-end'}
      alignItems={'center'}
      gap={1}
    >
      <Grid item>
        <Tooltip title={t('label.refresh')}>
          <span>
            <IconButton
              circled
              size={'medium'}
              disabled={disabled}
              onClick={async (e) => {
                e.preventDefault();
                await refresh();
              }}
            >
              <IconRefresh size={16} htmlColor={disabled ? '#dfdfee' : '#11193f'} />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <Grid item>
        {totalCount !== 0
          ? totalCount < page * pageSize
            ? `${(page - 1) * pageSize + 1} - ${totalCount}`
            : `${(page - 1) * pageSize + 1}-${page * pageSize} `
          : 0}
        {t('label.of')} {totalCount}
      </Grid>
      <Grid item>
        <Tooltip title={t('label.previous')}>
          <span>
            <IconButton
              circled
              size={'medium'}
              disabled={page === 1 || disabled}
              onClick={() => onChange?.(page - 1, pageSize)}
            >
              <IconArrowBack size={16} htmlColor={theme.palette.secondary.main} />
            </IconButton>
          </span>
        </Tooltip>
        {/*<Tooltip title={t('label.previous')}>*/}
        {/*  <span>*/}
        {/*    <IconButton*/}
        {/*      circled*/}
        {/*      size={'medium'}*/}
        {/*      disabled={disabled || page === 0}*/}
        {/*      onClick={async (e) => {*/}
        {/*        e.preventDefault();*/}
        {/*        setPage((prev) => prev - 1);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <IconChevronLeft*/}
        {/*        size={16}*/}
        {/*        htmlColor={disabled || page === 0 ? '#dfdfee' : '#11193f'}*/}
        {/*      />*/}
        {/*    </IconButton>*/}
        {/*  </span>*/}
        {/*</Tooltip>*/}
      </Grid>
      <Grid item>
        <Tooltip title={t('label.next')}>
          <span>
            <IconButton
              circled
              size={'medium'}
              disabled={totalCount <= page * pageSize || disabled}
              onClick={() => onChange?.(page + 1, pageSize)}
            >
              <IconArrowForward size={16} htmlColor={theme.palette.secondary.main} />
            </IconButton>
          </span>
        </Tooltip>
        {/*<Tooltip title={t('label.next')}>*/}
        {/*  <span>*/}
        {/*    <IconButton*/}
        {/*      circled*/}
        {/*      size={'medium'}*/}
        {/*      disabled={disabled || (page + 1) * pageSize >= totalCount}*/}
        {/*      onClick={async (e) => {*/}
        {/*        e.preventDefault();*/}
        {/*        setPage((prev) => prev + 1);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <IconChevronRight*/}
        {/*        size={16}*/}
        {/*        htmlColor={disabled || (page + 1) * pageSize >= totalCount ? '#dfdfee' : '#11193f'}*/}
        {/*      />*/}
        {/*    </IconButton>*/}
        {/*  </span>*/}
        {/*</Tooltip>*/}
      </Grid>
    </Grid>
  );
};

export default Pagination;
