import { createTheme } from '@mui/material/styles';
import { alpha } from '@mui/system';

const { palette } = createTheme();
const { augmentColor } = palette;

export const myTheme = {
  breakpoints: {
    values: {
      xs: 361,
      sm: 601,
      md: 961,
      lg: 1281,
      xl: 1921
    }
  },
  palette: {
    type: 'light',
    background: {
      default: '#F2F2F2',
      paper: '#FFF'
    },
    common: {
      black: '#000000',
      white: '#FFFFFF'
    },
    default: {
      900: '#000000',
      800: '#303637',
      700: '#53575D',
      600: '#666A71',
      500: '#91959A',
      400: '#B1B5BA',
      300: '#D5D9DE',
      200: '#E4E9EE',
      100: '#F1F4F6',
      50: '#F6F8F9',
      main: '#C3CBD4',
      contrastText: '#FFFFFF'
    },
    primary: {
      A700: '#2E2E31',
      A400: '#87878F',
      A200: '#BEC0C9',
      A100: '#D1D2D9',
      900: '#0011C0',
      800: '#0022D9',
      700: '#002FE3',
      600: '#003BF0',
      500: '#0144FC',
      400: '#2763EA',
      300: '#6280ff',
      200: '#96A4FE',
      100: '#C2C8FE',
      50: '#E7E9FF',
      main: '#2763EA',
      light: '#6280FF',
      dark: '#0011C0',
      contrastText: '#FFFFFF'
    },
    secondary: {
      A700: '#33525E',
      A400: '#668896',
      A200: '#A2B9C3',
      A100: '#C9D4F4',
      900: '#2B2B31',
      800: '#3C3D4F',
      700: '#5B5B6F',
      600: '#6F6F83',
      500: '#83839A',
      400: '#9898AD',
      300: '#B6B6CA',
      200: '#D0D0DF',
      100: '#DFDFEE',
      50: '#EFEFF6',
      main: '#3C3D4F',
      light: '#B6B6CA',
      dark: '#2B2B31',
      contrastText: '#FFFFFF'
    },
    info: {
      A700: '#00BFA5',
      A400: '#1DE9B6',
      A200: '#64FFDA',
      A100: '#A7FFEB',
      900: '#005739',
      800: '#007453',
      700: '#008461',
      600: '#009571',
      500: '#00A27D',
      400: '#29B190',
      300: '#51C0A2',
      200: '#83D2BC',
      100: '#B4E3D6',
      50: '#D0F4EF',
      main: '#29B190',
      light: '#51C0A2',
      dark: '#005739',
      contrastText: '#FFFFFF'
    },
    success: {
      900: '#005800',
      800: '#0F7600',
      700: '#218701',
      600: '#2E9812',
      500: '#39A71C',
      400: '#59B445',
      300: '#78C167',
      200: '#9FD193',
      100: '#C5E3BD',
      50: '#E7F4E4',
      main: '#2E9812',
      light: '#39A71C',
      dark: '#005800',
      contrastText: '#FFFFFF'
    },
    warning: {
      900: '#E65400',
      800: '#EF6F00',
      700: '#F57F00',
      600: '#FB8F01',
      500: '#FF9B02',
      400: '#FFA927',
      300: '#FFB94E',
      200: '#FFCD81',
      100: '#FFE1B3',
      50: '#FFF3E0',
      main: '#F57F00',
      light: '#FF9B02',
      dark: '#E65400',
      contrastText: '#FFFFFF'
    },
    error: {
      900: '#C82B06',
      800: '#E13A11',
      700: '#EF4116',
      600: '#FD491B',
      500: '#FF4F20',
      400: '#FFA927',
      300: '#FF8764',
      200: '#FFAA90',
      100: '#FFCBBC',
      50: '#FDE9E7',
      main: '#EF4116',
      light: '#FF4F20',
      dark: '#C82B06',
      contrastText: '#FFFFFF'
    },
    grayscale: {
      900: '#171717',
      800: '#373737',
      700: '#555555',
      600: '#686868',
      500: '#999999',
      400: '#B0B0B0',
      300: '#D5D5D5',
      200: '#F2F2F2',
      100: '#F8F8F8',
      50: '#FCFCFC',
      main: '#686868',
      light: '#F2F2F2',
      dark: '#171717',
      contrastText: '#FFFFFF'
    },
    grayscaleSecondary: augmentColor({
      color: {
        A700: '#565960',
        A400: '#9DA2AA',
        A200: '#D9DDE3',
        A100: '#C9D4F4',
        900: '#04060F',
        800: '#11193f',
        700: '#1B2856',
        600: '#2A396D',
        500: '#304075',
        400: '#4D5A87',
        300: '#6B769A',
        200: '#939BB7',
        100: '#BDC2D4',
        50: '#E5E7ED',
        main: '#11193f',
        light: '#939BB7',
        dark: '#04060F',
        contrastText: '#FFFFFF'
      }
    }),
    text: {
      primary: '#3C3D4F',
      secondary: '#6F6F83',
      disabled: '#999999'
    },
    divider: '#DFDFEE'
  },
  mixins: {
    toolbar: {
      minHeight: 60,
      '@media (min-width: 600px)': {
        minHeight: 60
      }
    }
  },
  typography: {
    fontFamily:
      '"Noto Sans KR",-apple-system,BlinkMacSystemFont,"Malgun Gothic", "Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";',
    htmlFontSize: 16,
    fontSize: 14,
    fontWeight: `400`,
    h1: {
      fontWeight: 'bold',
      fontSize: 28
    },
    h2: {
      fontWeight: 'bold',
      fontSize: 24
    },
    h3: {
      fontWeight: 'bold',
      fontSize: 20
    },
    h4: {
      fontWeight: 'bold',
      fontSize: 16
    },
    h5: {
      fontWeight: 'bold',
      fontSize: 15
    },
    subtitle1: {
      fontWeight: 'bold',
      fontSize: 14
    },
    subtitle2: {
      fontWeight: 'normal',
      fontSize: 14
    },
    body1: {
      fontWeight: 'normal',
      fontSize: 13
    },
    body2: {
      fontWeight: 'normal',
      fontSize: 12
    },
    caption: {
      fontSize: `11px`
    }
  },
  spacing: 8
};
export const overrideTheme = {
  ...myTheme,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      html {
        overflow: hidden !important;
      }
      html,body,#root {
        height: 100%;
      }
      .container {
          height: 100%;
      }
      body, button, input, select, textarea {
          font-family: 'Noto Sans KR', sans-serif;
          color: #3c3d4f ;
      }
      body {
        font-size: 14px;
          color: #3c3d4f !important;
      }
      a {
        color: inherit;
      }
      a:focus {
        outline: 0;
      }
      a:hover,
      a:active {
        outline: 0;
        text-decoration: underline;
      }
       button {
           margin: 0;
           padding: 0;
           border: none;
           background-color: transparent;
       }
       address {
        font-style: normal;
       }
      div ,
      ul {
          &::-webkit-scrollbar {
            width: 14px;
            height: 14px;
          }
          &::-webkit-scrollbar-track {
            background: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #dce1e5;
            border-radius: 8px;
            background-clip: padding-box;
            border: 4px solid transparent;
          }
      },
      *::before, *::after {
        box-sizing: initial;
      },
      input::-webkit-search-decoration,
      input::-webkit-search-cancel-button,
      input::-webkit-search-results-button,
      input::-webkit-search-results-decoration{
          display:none;
      }
    `
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: `5px 10px`,
          fontWeight: `normal`,
          fontSize: `13px`,
          marginTop: `4px !important`
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '.MuiSelect-select': {
            fontSize: `14px`,
            borderRadius: `2px`,
            padding: `4.9px 14px 9.98px 14px`
          },
          '.MuiSelect-icon': {
            top: `calc(50% - .6em)`
          },
          ':hover': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: `#2763ea`
            }
          },
          '&.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
              borderWidth: `1px`
            }
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          alignItems: `center`,
          ...((!ownerState.variant || ownerState.variant === 'standard') && {
            ...(ownerState.color && {
              color: myTheme.palette.text.primary,
              border: `1px solid ${myTheme.palette[ownerState.color][200]}`,
              backgroundColor: alpha(myTheme.palette[ownerState.color][50], 0.5),
              '.MuiAlert-message': {
                fontSize: `0.875rem`
              }
            })
          })
        })
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: () => ({
          '.MuiInputBase-root': {
            gap: myTheme.spacing,
            '.MuiAutocomplete-input': {
              padding: `0.95px 4px 0.95px 5px`
            },
            '& .Mui-disabled': {
              color: alpha(myTheme.palette.grayscaleSecondary[50], 0.4)
            }
          },
          '.MuiOutlinedInput-notchedOutline': {
            top: 0
          }
        }),

        inputRoot: {
          padding: `9px`,
          fontSize: `14px`
        },
        tag: {
          margin: 0,
          '&:not(div)': {
            color: myTheme.palette.primary.contrastText,
            backgroundColor: myTheme.palette.primary.main,
            paddingLeft: myTheme.spacing,
            paddingRight: myTheme.spacing,
            borderRadius: myTheme.spacing * 2
          }
        },
        popper: {
          '.MuiAutocomplete-listbox': {
            '.MuiAutocomplete-option': {
              minHeight: `40px`,
              '&:hover, &.Mui-focused': {
                backgroundColor: alpha(myTheme.palette.secondary.main, 0.08)
              },
              '&.Mui-selected': {
                backgroundColor: alpha(myTheme.palette.grayscaleSecondary.A100, 0.5)
              }
            }
          }
        }
      },
      variants: [
        {
          props: {
            size: 'small'
          },
          style: {
            '.MuiInputBase-root': {
              '&.MuiInputBase-sizeSmall': {
                paddingTop: `4px`,
                paddingBottom: `4px`
              }
            }
          }
        }
      ]
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: `1rem`,
          backgroundColor: myTheme.palette.common.white,
          '&:hover': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: myTheme.palette.primary.main
            }
          },
          '&.Mui-disabled': {
            '.MuiOutlinedInput-notchedOutline': {
              backgroundColor: alpha(myTheme.palette.grayscaleSecondary[50], 0.2)
            },
            '&:hover': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: myTheme.palette.secondary[300]
              }
            }
          },
          input: {
            padding: `0.71rem 0.875rem`,
            "&[type='search']": {
              '&::-webkit-search-cancel-button': {
                appearance: `none`,
                height: `20px`,
                width: `20px`,
                background: `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' %3E%3Cpath d='M6 12a6 6 0 1 1 6-6 6.006 6.006 0 0 1-6 6zm0-4.943 2.12 2.12 1.06-1.06L7.063 6l2.121-2.124-1.06-1.06L6 4.936 3.881 2.815l-1.06 1.06L4.942 6 2.821 8.118l1.06 1.06L6 7.058z' fill='%23939bb7' /%3E%3C/svg%3E") no-repeat 50% 50%`,
                backgroundSize: `12px 12px`,
                cursor: `pointer`
              }
            }
          },
          "&:has(input[type='search'])": {
            input: {
              paddingLeft: 0
            },
            '.MuiInputAdornment-positionStart': {
              width: 16,
              transition: `width 0.2s linear`
            }
          },
          '&:active, &:focus, &.Mui-focused': {
            "&:has(input[type='search'])": {
              '.MuiInputAdornment-positionStart': {
                width: 0,
                height: 0,
                overflow: `hidden`
              }
            }
          }
        },
        notchedOutline: {
          top: 0,
          borderWidth: `1px !important`,
          borderColor: myTheme.palette.secondary[300]
        },
        multiline: {
          height: `auto`,
          minHeight: 88,
          padding: myTheme.spacing * 2,
          alignItems: `flex-start`,
          borderRadius: 2
        }
      },
      variants: [
        {
          props: {
            size: 'small'
          },
          style: {
            paddingLeft: 8,
            paddingRight: 8
          }
        }
      ]
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontSize: `1rem`,
          borderRadius: `4px`,
          "&:has(input[type='search'])": {
            paddingLeft: `0.75rem`,
            paddingRight: `0.5rem`,
            border: `1px solid transparent`,
            '&:hover, &:focus, &.Mui-focused': {
              backgroundColor: myTheme.palette.common.white,
              border: `1px solid ${myTheme.palette.primary.main}`
            }
          },
          '&:before, &:after': {
            content: `none`
          },
          input: {
            padding: `0.71rem 0.875rem`,
            "&[type='search']": {
              paddingLeft: 0,
              paddingRight: 0,
              '&::-webkit-search-cancel-button': {
                appearance: `none`,
                height: `20px`,
                width: `20px`,
                background: `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' %3E%3Cpath d='M6 12a6 6 0 1 1 6-6 6.006 6.006 0 0 1-6 6zm0-4.943 2.12 2.12 1.06-1.06L7.063 6l2.121-2.124-1.06-1.06L6 4.936 3.881 2.815l-1.06 1.06L4.942 6 2.821 8.118l1.06 1.06L6 7.058z' fill='%23939bb7' /%3E%3C/svg%3E") no-repeat 50% 50%`,
                backgroundSize: `12px 12px`,
                cursor: `pointer`
              }
            }
          },
          '.MuiInputAdornment-positionStart': {
            marginTop: `0 !important`
          }
        },
        sizeSmall: {
          input: {
            padding: `0.3711rem 0.5rem`
          }
        },
        inputAdornedStart: {
          marginTop: `0 !important`
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 8,
          marginLeft: 0,
          fontSize: `0.875rem`
        }
      }
    }
  }
};

const theme = createTheme({ ...overrideTheme });

export default theme;
