import React, { useState } from 'react';
import ModalContainer from '@/components/common/containers/ModalContainer';
import PropTypes from 'prop-types';
import PurgeJobOverview from '@/components/manage/job/purge/PurgeJobOverview';
import PurgeJobFileObjectList from '@/components/manage/job/purge/PurgeJobFileObjectList';
import JobTask from '@/components/manage/job/common/JobTask';
import { Box } from '@mui/system';
import { Divider, Grid, Typography } from '@mui/material';
import { IconButton, Tooltip } from '@mzc-cloudplex/core';
import theme from '@/styles/theme';
import {
  IconCollapseWindow,
  IconInfoDetails,
  IconExpandWindow,
  IconLeave,
  IconPurge
} from '@mzc-cloudplex/icons';
import FileObjectName from '@/components/manage/job/common/FileObjectName';
import { useTranslation } from 'react-i18next';
import CircleSpinner from '@/components/common/spinners/CircleSpinner';
import MyEventObserver from '@/contexts/MyEventObserver';
import usePurgeJobDetail from '@/components/manage/job/hooks/usePurgeJobDetail';

const PurgeJobDetailModal = ({ spaceId, job, onClose }) => {
  const { t } = useTranslation('jobs');
  const [task, setTask] = useState(null);
  const [panelOpen, setPanelOpen] = useState(false);
  const [panelExpanded, setPanelExpanded] = useState(false);

  const handleClickPanelOpen = () => {
    setPanelOpen(true);
  };
  const handleClosePanelOpen = () => {
    setPanelOpen(false);
    setPanelExpanded(false);
  };

  const handleToggleExpandPanel = () => {
    setPanelExpanded(!panelExpanded);
  };

  const { jobViewModel, isLoading } = usePurgeJobDetail({
    spaceId,
    projectId: job.project.id,
    jobId: job.id,
    eventObserver: MyEventObserver
  });

  return (
    <ModalContainer
      title={
        <Grid
          container
          alignItems={'center'}
          justifyContent={'space-between'}
          flexWrap={'nowrap'}
          pr={3}
        >
          <Grid item xs>
            <Grid container gap={1} alignItems={'center'} flexWrap={'nowrap'}>
              <Box
                sx={{
                  display: `inline-flex`,
                  alignItems: `center`,
                  justifyContent: `center`,
                  width: 28,
                  minWidth: 28,
                  height: 22,
                  borderRadius: `2px`,
                  backgroundColor: theme.palette.secondary.main
                }}
              >
                <IconPurge size={16} htmlColor={theme.palette.common.white} />
              </Box>

              <FileObjectName
                path={jobViewModel.name}
                total={jobViewModel.fileObjectCount}
                variant={'header'}
                iconVisible={false}
              />
            </Grid>
          </Grid>
        </Grid>
      }
      width={1280}
      maxWidth={false}
      modalType={'panel'}
      onClose={onClose}
      onClickClose={onClose}
      onClickConfirm={true}
      headerClose={true}
      panel={
        <>
          {panelOpen ? (
            <Grid container flexDirection={`column`} flexWrap={'nowrap'} sx={{ height: `100%` }}>
              <Grid
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                height={60}
                p={1.5}
                pr={1}
              >
                <Grid item>
                  <Typography variant={'h4'}>{t(`jobDetail`)}</Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    title={`${panelExpanded ? t(`common::label.collapsePanel`) : t(`common::label.expandPanel`)}`}
                  >
                    <span>
                      <IconButton size={'medium'} circled onClick={handleToggleExpandPanel}>
                        {panelExpanded ? (
                          <IconCollapseWindow size={16} color={'secondary'} />
                        ) : (
                          <IconExpandWindow size={16} color={'secondary'} />
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={t(`common::label.closePanel`)}>
                    <span>
                      <IconButton size={'medium'} circled onClick={handleClosePanelOpen}>
                        <IconLeave size={16} color={'secondary'} />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
              <Divider />
              <Grid item xs sx={{ overflowY: `auto` }}>
                <JobTask job={jobViewModel} task={task} title={t('purge')} />
              </Grid>
            </Grid>
          ) : (
            <Tooltip title={t(`jobDetail`)}>
              <span>
                <IconButton circled size={'medium'} onClick={handleClickPanelOpen}>
                  <IconInfoDetails size={16} color={'secondary'} />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </>
      }
      panelOpen={panelOpen}
      panelExpand={panelExpanded}
    >
      <Divider sx={{ mt: -1, borderColor: theme.palette.secondary.main }} />
      {isLoading ? (
        <CircleSpinner />
      ) : (
        <>
          <PurgeJobOverview spaceId={spaceId} job={jobViewModel} targetCdns={jobViewModel.cdns} />
          <PurgeJobFileObjectList
            spaceId={spaceId}
            job={jobViewModel}
            onItemClick={(clickedTask) => {
              setTask(clickedTask);
              setPanelOpen(true);
            }}
            onChangeTask={(changedTask) => {
              setTask((prevTask) => {
                if (prevTask != null && prevTask.id === changedTask.id) return changedTask;
                return prevTask;
              });
            }}
          />
        </>
      )}
    </ModalContainer>
  );
};

PurgeJobDetailModal.propTypes = {
  spaceId: PropTypes.string.isRequired,
  job: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export default PurgeJobDetailModal;
