import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutationToPurge } from '@/hooks/mutations/useMutationToPurge';
import { Drawer, Grid, Typography, Divider } from '@mui/material';
import { IconClose, IconCompare, IconDragdropUpload, IconPurge } from '@mzc-cloudplex/icons';
import { IconButton, Tabs, Tooltip } from '@mzc-cloudplex/core';
import { useDeliveryLocation } from '@/hooks/useDeliveryLocation';
import theme from '@/styles/theme';
import UploadTab from '@/components/myJob/tabs/UploadTab';
import PurgeTab from '@/components/myJob/tabs/PurgeTab';
import usePurgeJobDetailModal from '@/components/myJob/hooks/usePurgeJobDetailModal';
import useCompareJobV4DetailModal from '@/components/myJob/hooks/useCompareJobV4DetailModal';
import MyEventObserver from '@/contexts/MyEventObserver';
import { JOB_EVENTS } from '@/hooks/useEventObserver';
import CompareTab from '@/components/myJob/tabs/compareApiV4/CompareTab';
import useCompareJobV3DetailModal from '@/components/myJob/hooks/useCompareJobV3DetailModal';

const JobListContainer = ({ isJobPanelOpen, closeJobPanel }) => {
  const { t } = useTranslation('jobs');
  const { spaceId } = useDeliveryLocation();
  const { Modal: PurgeJobDetailModal, show: showPurgeJobDetailModal } = usePurgeJobDetailModal({
    spaceId: spaceId
  });

  const { Modal: CompareJobV3DetailModal, show: showCompareJobV3DetailModal } =
    useCompareJobV3DetailModal({ spaceId: spaceId });

  const { Modal: CompareJobDetailModal, show: showCompareJobDetailModal } =
    useCompareJobV4DetailModal({ spaceId: spaceId });

  const tabIndexRef = useRef(0);

  const [tabTypes, setTabTypes] = useState([
    {
      label: `${t('upload')}`,
      icon: <IconDragdropUpload size={16} />,
      value: 0,
      badge: false
    },
    { label: `${t('purge')}`, icon: <IconPurge size={16} />, value: 1, badge: false },
    { label: `${t('compare')}`, icon: <IconCompare size={16} />, value: 2, badge: false }
  ]);
  const { subscribe, unsubscribe } = useContext(MyEventObserver.context);

  const createBadge = (index) => {
    if (index !== tabIndexRef.current) {
      setTabTypes((prev) => {
        prev[index].badge = true;
        return [...prev];
      });
    }
  };

  useEffect(() => {
    const createPurgeBadge = () => createBadge(1);
    const createCompareBadge = () => createBadge(2);

    subscribe(JOB_EVENTS.PURGE_JOB, createPurgeBadge);
    subscribe(JOB_EVENTS.COMPARE_JOB, createCompareBadge);
    return () => {
      unsubscribe(JOB_EVENTS.PURGE_JOB, createPurgeBadge);
      unsubscribe(JOB_EVENTS.COMPARE_JOB, createCompareBadge);
    };
  }, []);

  const handleChange = (event, newValue) => {
    tabIndexRef.current = newValue;
    setTabTypes((prev) => {
      prev[newValue].badge = false;
      return [...prev];
    });
  };

  const { mutateAsync: mutateToPurge } = useMutationToPurge();

  return (
    <Drawer
      anchor='right'
      open={isJobPanelOpen}
      onClose={closeJobPanel}
      sx={{
        left: `initial`,
        width: `640px`,
        '& .MuiDrawer-paper': {
          width: `640px`,
          boxSizing: 'border-box'
        },
        '& .MuiBackdrop-root': {
          backgroundColor: `transparent`
        }
      }}
    >
      <Grid container height={`100%`} flexDirection={'column'} flexWrap={'nowrap'}>
        <Grid item>
          <Grid
            container
            alignItems={'center'}
            justifyContent={'space-between'}
            py={1.5}
            pl={3}
            pr={1}
          >
            <Typography variant={'h4'} component={'p'}>
              {t('myJob')}
            </Typography>
            <Tooltip title={t('common::label.close')}>
              <span>
                <IconButton circled size={'medium'} onClick={closeJobPanel}>
                  <IconClose size={16} color={'secondary'} />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
        <Divider />
        <Grid item>
          <Tabs
            value={tabIndexRef.current}
            onChange={handleChange}
            indicatorColor={'secondary'}
            textColor={'secondary'}
            options={tabTypes}
            sx={{
              '.MuiTab-root': {
                minHeight: 46,
                fontSize: 14,
                border: `none`,
                '&.Mui-selected ': {
                  fontWeight: `bold`,
                  border: `none`
                }
              },
              '.MuiTabs-indicator': {
                top: `inherit`,
                bottom: 0
              }
            }}
          />
        </Grid>
        <Divider sx={{ borderColor: theme.palette.secondary.main }} />
        <Grid item xs>
          {tabIndexRef.current === 0 && <UploadTab purge={mutateToPurge} />}
          {tabIndexRef.current === 1 && (
            <>
              <PurgeTab spaceId={spaceId} showDetailModal={showPurgeJobDetailModal} />
              <PurgeJobDetailModal />
            </>
          )}
          {tabIndexRef.current === 2 && (
            <>
              <CompareTab
                spaceId={spaceId}
                showV3DetailModal={showCompareJobV3DetailModal}
                showDetailModal={showCompareJobDetailModal}
              />
              <CompareJobV3DetailModal />
              <CompareJobDetailModal />
            </>
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default JobListContainer;
