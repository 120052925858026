import React from 'react';
import { IconSameCircle, IconErrorCircle, IconDifferent } from '@mzc-cloudplex/icons';
import { COMPARE_TASK_RESULT, COMPARE_TASK_STATUS } from '@/services/job/compareJobService';
import theme from '@/styles/theme';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { IconBox } from '@packages/cp-ui';
import { CircleProgress } from '@mzc-cloudplex/core';

const CompareTaskResult = ({ status, result }) => {
  const { t } = useTranslation('jobs');
  if (result === COMPARE_TASK_RESULT.IDENTICAL) {
    return (
      <Box display={'inline-flex'} alignItems={'center'} gap={1}>
        <IconSameCircle size={12} color={'success'} />
        <Typography color={theme.palette.success.main} fontWeight={500}>
          {t('identical')}
        </Typography>
      </Box>
    );
  } else if (result === COMPARE_TASK_RESULT.DIFFERENT) {
    return (
      <Box display={'inline-flex'} alignItems={'center'} gap={1}>
        <IconBox size={12} backgroundColor={theme.palette.warning.main}>
          <IconDifferent size={10} htmlColor={'#fff'} />
        </IconBox>
        <Typography color={theme.palette.warning.main} fontWeight={500}>
          {t('different')}
        </Typography>
      </Box>
    );
  } else if (status === COMPARE_TASK_STATUS.PENDING) {
    return (
      <Box display={'inline-flex'} alignItems={'center'} gap={1}>
        <CircleProgress size={12} color={'inherit'} thickness={5} />
        <Typography color={theme.palette.grayscaleSecondary[300]} fontWeight={500}>
          {t('pending')}
        </Typography>
      </Box>
    );
  } else if (status === COMPARE_TASK_STATUS.IN_PROGRESS) {
    return (
      <Box display={'inline-flex'} alignItems={'center'} gap={1}>
        <CircleProgress size={12} color={'primary'} thickness={5} />
        <Typography color={theme.palette.primary.main} fontWeight={500}>
          {t('inProgress')}
        </Typography>
      </Box>
    );
  } else if (status === COMPARE_TASK_STATUS.FAILED) {
    return (
      <Box display={'inline-flex'} alignItems={'center'} gap={1}>
        <IconErrorCircle size={12} color={'error'} />
        <Typography color={theme.palette.error.main} fontWeight={500}>
          {t('failed')}
        </Typography>
      </Box>
    );
  }
};

export default CompareTaskResult;
