import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProjectName from '@/components/myJob/tabs/ProjectName';
import Pagination from '@/components/myJob/tabs/Pagination';
import { alpha, Box } from '@mui/system';
import { LinearProgress } from '@mzc-cloudplex/core';
import { IconEmptyData } from '@mzc-cloudplex/icons';
import compareJobService, { COMPARE_JOB_V4_SPEC } from '@/services/job/compareJobService';
import { Divider, Grid, Typography, ListItem } from '@mui/material';
import { List, ListItemText } from '@/components/myJob/tabs/styles/tabs.styled';
import theme from '@/styles/theme';
import FileObjectName from '@/components/manage/job/common/FileObjectName';
import { MyJobSkeleton } from '@/components/myJob/tabs/MyJobSkeleton';
import { MyJobResult } from '@/components/myJob/tabs/compareApiV4/MyJobResult';
import { MyJobResult as MyJobResultV3 } from '@/components/myJob/tabs/MyJobResult';
import MyEventObserver from '@/contexts/MyEventObserver';
import useCompareJobDetailV4 from '@/components/manage/job/compareApiV4/hooks/useCompareJobDetail';
import useCompareJobDetailV3 from '@/components/manage/job/hooks/useCompareJobDetail';
import CdnIconName from '@/components/common/CdnIconName';
import { useQueryToListMyCompareJobsV4 } from '@/hooks/queries/useQueryToListMyCompareJobsV4';
import CompareJobResultLabel from '@/components/manage/job/common/compare/CompareJobResultLabel';

//my job compare tab V4
const CompareTab = ({ spaceId, showV3DetailModal, showDetailModal }) => {
  const { t } = useTranslation('jobs');
  const [page, setPage] = useState(1);
  const { data: myCompareJobList, isLoading } = useQueryToListMyCompareJobsV4(
    { spaceId: spaceId },
    { page: page - 1, size: 10 }
  );

  const [topDivider, setTopDivider] = useState(false);

  const handleScrolling = (e) => {
    if (e === undefined) return;
    const { scrollTop } = e.currentTarget;

    setTopDivider(scrollTop !== 0);
  };

  if (isLoading) return <MyJobSkeleton />;

  return (
    <>
      <Grid container height={`100%`} flexDirection={'column'} flexWrap={'nowrap'}>
        <Grid item>
          <Grid
            container
            alignItems={`center`}
            justifyContent={`space-between`}
            p={1.5}
            sx={{ boxShadow: topDivider ? `0 3px 6px 0 rgba(0, 0, 0, 0.16);` : `none` }}
          >
            <Grid item>
              <Grid container alignItems={`center`}>
                <Grid item>
                  <Typography variant={'body1'} component={'span'}>
                    {compareJobService.lastLoadTime}
                  </Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    disabled={isLoading}
                    page={page}
                    pageSize={10}
                    onChange={(page) => setPage(page)}
                    totalCount={myCompareJobList.totalCount}
                    refresh={myCompareJobList.refresh}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid item xs pl={3} pr={3} pt={1.5} sx={{ overflowY: `auto` }} onScroll={handleScrolling}>
          {isLoading ? (
            <MyJobSkeleton />
          ) : (myCompareJobList?.results.length ?? 0) < 1 ? (
            <Box pt={4} pb={4} textAlign={'center'}>
              <IconEmptyData size={58} style={{ color: theme.palette.grayscale[300] }} />
              <Typography mt={2}>{t('noOperation')}.</Typography>
            </Box>
          ) : (
            myCompareJobList.results.map((job, jobIndex) =>
              job.spec === COMPARE_JOB_V4_SPEC ? (
                <JobItem
                  key={jobIndex}
                  spaceId={spaceId}
                  job={job}
                  onShowDetail={showDetailModal}
                />
              ) : (
                <JobItemV3
                  key={jobIndex}
                  spaceId={spaceId}
                  job={job}
                  onShowDetail={showV3DetailModal}
                />
              )
            )
          )}
        </Grid>
      </Grid>
    </>
  );
};

//my job compare tab job item
const JobItemV3 = React.memo(({ spaceId, job: listItem, onShowDetail }) => {
  const { t } = useTranslation('jobs');
  const { jobViewModel, isForbidden, isUnauthorized, isLoading } = useCompareJobDetailV3({
    spaceId,
    projectId: listItem.project.id,
    jobId: listItem.id,
    eventObserver: MyEventObserver
  });

  if (isLoading) return <MyJobSkeleton />;

  return (
    <div>
      <Grid container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item>
          <CompareJobResultLabel status={jobViewModel.resultInfo.status} />
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1 }} />
      <Box sx={{ paddingBottom: 4 }}>
        <List>
          <ListItem disablePadding>
            <ListItemText
              sx={{ my: 0.5 }}
              disableTypography
              primary={<Typography className={'MuiListItemText-primary'}>{t('object')}</Typography>}
              secondary={
                <FileObjectName
                  variant={'panel'}
                  path={jobViewModel.name}
                  total={jobViewModel.fileObjectCount}
                  onClick={
                    isForbidden || isUnauthorized
                      ? null
                      : () => onShowDetail?.({ job: jobViewModel })
                  }
                />
              }
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              sx={{ my: 0.5 }}
              disableTypography
              primary={
                <Typography className={'MuiListItemText-primary'}>{t('project')}</Typography>
              }
              secondary={
                <div style={{ maxWidth: '400px', overflowWrap: 'break-word' }}>
                  <ProjectName spaceId={spaceId} projectId={jobViewModel.project.id} />
                </div>
              }
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              sx={{ my: 0.5 }}
              disableTypography
              primary={<Typography className={'MuiListItemText-primary'}>CDN(s)</Typography>}
              secondary={
                <Grid container gap={0.5}>
                  {jobViewModel.cdns.map((cdn) => (
                    <Grid item key={cdn.id}>
                      <CdnIconName service={cdn.service} />
                    </Grid>
                  ))}
                </Grid>
              }
            />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemText
              sx={{ my: 0.5 }}
              primary={t('requestedAt')}
              secondary={jobViewModel.createdAt}
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              sx={{ my: 0.5 }}
              primary={t('completedAt')}
              secondary={jobViewModel.completedAt ? jobViewModel.completedAt : '-'}
            />
          </ListItem>
        </List>
        <Box
          sx={{
            mt: 1,
            backgroundColor: alpha(theme.palette.grayscaleSecondary[50], 0.5),
            padding: 2,
            borderRadius: `2px`
          }}
        >
          <Box display={'flex'} alignItems={'center'} gap={0.5}>
            <Typography variant={'body2'} fontWeight={'bold'} color={'primary'}>
              {jobViewModel.resultInfo.identicalCount +
                jobViewModel.resultInfo.differentCount +
                jobViewModel.resultInfo.failedCount}
            </Typography>
            <Typography variant={'body2'} fontWeight={'bold'}>
              / {jobViewModel.resultInfo.totalCount}
            </Typography>
            <Typography variant={'body2'} fontWeight={'bold'}>
              {t('task')}{' '}
              {jobViewModel.resultInfo.status === 'IN_PROGRESS'
                ? t('inProgress')
                : jobViewModel.resultInfo.status === 'PREPARED'
                  ? t('pending')
                  : jobViewModel.resultInfo.status === 'FAILED'
                    ? t('failed')
                    : t('completed')}
            </Typography>
          </Box>
          <Box pt={1} pb={1.5}>
            <LinearProgress
              height={6}
              value={
                jobViewModel.resultInfo.status === 'PREPARED'
                  ? 0
                  : ((jobViewModel.resultInfo.identicalCount +
                      jobViewModel.resultInfo.differentCount +
                      jobViewModel.resultInfo.failedCount) /
                      jobViewModel.resultInfo.totalCount) *
                    100
              }
              variant='determinate'
            />
          </Box>
          <MyJobResultV3 job={jobViewModel} />
        </Box>
      </Box>
    </div>
  );
});

const JobItem = React.memo(({ spaceId, job: listItem, onShowDetail }) => {
  const { t } = useTranslation('jobs');
  const { dataToGetCompareJob, isLoading, isForbidden, isUnauthorized } = useCompareJobDetailV4({
    spaceId,
    projectId: listItem.project.id,
    jobId: listItem.id,
    eventObserver: MyEventObserver
  });

  if (isLoading) return <MyJobSkeleton />;
  return (
    <div>
      <Grid container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item>
          <CompareJobResultLabel status={dataToGetCompareJob.getResult()} />
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 1 }} />
      <Box sx={{ paddingBottom: 4 }}>
        <List>
          <ListItem disablePadding>
            <ListItemText
              sx={{ my: 0.5 }}
              disableTypography
              primary={<Typography className={'MuiListItemText-primary'}>{t('object')}</Typography>}
              secondary={
                <FileObjectName
                  variant={'panel'}
                  path={dataToGetCompareJob.name}
                  total={dataToGetCompareJob.fileObjectCount}
                  onClick={
                    isForbidden || isUnauthorized
                      ? null
                      : () => onShowDetail?.({ job: dataToGetCompareJob })
                  }
                />
              }
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              sx={{ my: 0.5 }}
              disableTypography
              primary={
                <Typography className={'MuiListItemText-primary'}>{t('project')}</Typography>
              }
              secondary={
                <div style={{ maxWidth: '400px', overflowWrap: 'break-word' }}>
                  <ProjectName spaceId={spaceId} projectId={dataToGetCompareJob.project.id} />
                </div>
              }
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              sx={{ my: 0.5 }}
              disableTypography
              primary={<Typography className={'MuiListItemText-primary'}>CDN(s)</Typography>}
              secondary={
                <Grid container gap={0.5}>
                  {dataToGetCompareJob.cdns.map((cdn) => (
                    <Grid item key={cdn.id}>
                      <CdnIconName service={cdn.service} />
                    </Grid>
                  ))}
                </Grid>
              }
            />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemText
              sx={{ my: 0.5 }}
              primary={t('requestedAt')}
              secondary={dataToGetCompareJob.createdAt}
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              sx={{ my: 0.5 }}
              primary={t('completedAt')}
              secondary={dataToGetCompareJob.completedAt ? dataToGetCompareJob.completedAt : '-'}
            />
          </ListItem>
        </List>
        <Box
          sx={{
            mt: 1,
            backgroundColor: alpha(theme.palette.grayscaleSecondary[50], 0.5),
            padding: 2,
            borderRadius: `2px`
          }}
        >
          <Box display={'flex'} alignItems={'center'} gap={0.5}>
            <Typography variant={'body2'} fontWeight={'bold'} color={'primary'}>
              {dataToGetCompareJob.summary.identicalCount +
                dataToGetCompareJob.summary.differentCount +
                dataToGetCompareJob.summary.failedCount}
            </Typography>
            <Typography variant={'body2'} fontWeight={'bold'}>
              / {dataToGetCompareJob.summary.totalCount}
            </Typography>
            <Typography variant={'body2'} fontWeight={'bold'}>
              {t('task')}{' '}
              {dataToGetCompareJob.getResult() === 'IN_PROGRESS'
                ? t('inProgress')
                : dataToGetCompareJob.getResult() === 'PREPARED'
                  ? t('pending')
                  : dataToGetCompareJob.getResult() === 'FAILED'
                    ? t('failed')
                    : t('completed')}
            </Typography>
          </Box>
          <Box pt={1} pb={1.5}>
            <LinearProgress
              height={6}
              value={
                dataToGetCompareJob.summary.status === 'PREPARED'
                  ? 0
                  : ((dataToGetCompareJob.summary.identicalCount +
                      dataToGetCompareJob.summary.differentCount +
                      dataToGetCompareJob.summary.failedCount) /
                      dataToGetCompareJob.summary.totalCount) *
                    100
              }
              variant='determinate'
            />
          </Box>
          <MyJobResult job={dataToGetCompareJob} />
        </Box>
      </Box>
    </div>
  );
});

export default CompareTab;
