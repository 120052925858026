import * as React from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';
import theme, { myTheme } from '@/styles/theme';

export default function GlobalCssOverride() {
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{
          iframe: { display: 'none' },
          '.recharts-legend-wrapper': {
            paddingTop: `34px`,
            paddingLeft: `20px`,
            paddingRight: `20px`,
            display: `flex`,
            gap: `5px 10px`,
            flexWrap: `wrap`
          },
          '.MuiSvgIcon-root': {
            display: `inline-block`,
            overflow: `visible`,
            boxSizing: `content-box`
          },
          '.bullet': {
            marginRight: `8px`
          },
          '.content-wrapper': {
            width: `100%`
          },
          '.svgIconWrap': {
            display: `inline-flex`,
            alignItems: `center`,
            justifyContent: `center`,
            verticalAlign: `middle`,
            '.MuiSvgIcon-root': {
              margin: `0 !important`
            },
            '+ span, + strong': {
              marginLeft: `8px`
            }
          },

          '.select2-selection__menu-portal': {
            zIndex: `1500 !important`
          },
          '.count-select': {
            position: `relative`,
            display: `inline-flex`,
            width: `100%`,
            '> div': {
              width: `100%`
            },
            '.select2-selection__value-container': {
              paddingRight: `30px !important`
            },
            '.badge-count': {
              position: `absolute`,
              right: `45px`,
              top: `50%`,
              minWidth: `20px`,
              height: `20px`,
              transform: `translateY(-50%)`,
              padding: `1px 6px 3px 6px`,
              backgroundColor: `#2763ea`,
              display: `inline-flex`,
              color: `#fff`,
              borderRadius: `100%`,
              fontSize: `12px`,
              alignItems: `center`,
              justifyContent: `center`
            }
          },
          '.Toastify': {
            '&__toast': {
              minHeight: 0,
              opacity: 0.9,
              borderRadius: 5,
              boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.16) `,
              color: myTheme.palette.common.white,
              '&__body': {
                padding: 10
              },
              '&--success': {
                backgroundColor: myTheme.palette.success.main
              },
              '&--error': {
                backgroundColor: myTheme.palette.error.main
              }
            },
            '&__close-button': {
              color: myTheme.palette.common.white
            }
          },

          // css 초기화 코드, 나중에 cloudplex.css 걷어내면 삭제
          '.pagination-input': {
            position: `relative`,
            display: `inline-block`,
            '+.select2-container': {
              minWidth: `150px`
            },
            '.select2-container': {
              minWidth: ` 0`,
              width: `80px`,
              '.select2-selection__value-container': {
                cursor: `pointer`
              },
              '&.select2-focus': {
                '+ .btn': {
                  zIndex: `10`
                }
              }
            },
            '.btn': {
              position: `absolute`,
              top: `1px`,
              right: `1px`,
              bottom: `1px`,
              width: `27px`,
              padding: 0,
              justifyContent: `center`,
              borderLeft: `1px solid #dfdfee`,
              pointerEvents: `none`,
              backgroundColor: `#fff`,
              ' .sprite': {
                opacity: 0.7
              }
            }
          },
          'input[type="text"].select2-selection__input': {
            height: `auto`
          },
          '.form-input': {
            position: `relative`,
            '&.form-error': {
              input: {
                paddingRight: `40px`,
                borderColor: theme.palette.error.main
              }
            },
            input: {
              width: `100%`,
              height: `38px`,
              padding: `0 20px`,
              border: `1px solid #B6B6CA`,
              borderRadius: `2px`,

              '&:hover, &:focus': {
                borderColor: theme.palette.primary.main
              }
            }
          },
          '.form-textarea': {
            textarea: {
              width: `100%`,
              height: `100%`,
              minHeight: `88px`,
              border: `1px solid #B6B6CA`,
              padding: `16px 20px`,
              '&:hover, &:focus': {
                borderColor: theme.palette.primary.main,
                borderWidth: `1px`,
                outline: `none`
              }
            }
          },
          '.MuiTableCell-head': {
            height: 48,
            whiteSpace: `nowrap`
          },
          '.dialog-header': {
            height: `auto !important`,
            padding: `20px 24px`
          }
        }}
      />
    </React.Fragment>
  );
}
