import React from 'react';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { Button } from '@mzc-cloudplex/core';
import theme from '@/styles/theme';
import { Stack } from '@mui/system';
import Popover from '@mui/material/Popover';

const UserInfo = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        size={'small'}
        variant={'text'}
        color={'secondary'}
        sx={{ minWidth: 0 }}
        onClick={handlePopoverOpen}
      >
        {props.user.name}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '.MuiPaper-root ': {
            width: `250px`,
            p: 2.5,
            border: `1px solid ${theme.palette.secondary[600]}`,
            borderRadius: 0.5
          }
        }}
      >
        <Box>
          <Grid container gap={1} alignItems={'baseline'}>
            <Grid item>
              <Avatar
                sx={{ width: 18, height: 18, backgroundColor: theme.palette.secondary[500] }}
              />
            </Grid>
            <Grid item>
              <Stack>
                <Typography variant={'subtitle1'}>{props.user.name}</Typography>
                <Typography variant={'subtitle2'} color={theme.palette.text.secondary}>
                  {props.user.username}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          {/* TODO: 부가 정보 있을 시 노출 */}
          {/*<Divider sx={{mt: 2, mb: 2}}/>*/}
          {/*<Box*/}
          {/*  sx={{*/}
          {/*    maxHeight: 100,*/}
          {/*    overflowY: `auto`*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <List disablePadding>*/}
          {/*    <ListItem disablePadding>*/}
          {/*      <ListItemText*/}
          {/*        primary={'Display Name'}*/}
          {/*        secondary={'Display name'}*/}
          {/*        sx={{*/}
          {/*          '.MuiListItemText-primary': {*/}
          {/*            '&:before': {*/}
          {/*              content: '"·"',*/}
          {/*            }*/}
          {/*          },*/}
          {/*          '.MuiListItemText-secondary': {*/}
          {/*            marginLeft: 1*/}
          {/*          }*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    </ListItem>*/}
          {/*  </List>*/}
          {/*</Box>*/}
        </Box>
      </Popover>
    </>
  );
};

export default UserInfo;
