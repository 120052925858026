import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { CircleProgress } from '@mzc-cloudplex/core';
import theme from '@/styles/theme';
import {
  IconCompleteCircle,
  IconErrorCircle,
  IconDifferent,
  IconMedium
} from '@mzc-cloudplex/icons';
import { useTranslation } from 'react-i18next';

export const MyJobResult = ({ job }) => {
  const { t } = useTranslation('jobs');
  return (
    <Stack direction='row' justifyContent='flex-start' spacing={2} component={'div'}>
      {job.resultInfo.pendingCount > 0 && (
        <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
          <CircleProgress size={12} color={'inherit'} thickness={5} />
          <Grid item>
            <Typography variant={'body2'} color={theme.palette.text.secondary}>
              <strong>{job.resultInfo.pendingCount}</strong> {t('pending')}
            </Typography>
          </Grid>
        </Grid>
      )}
      {job.resultInfo.inProgressCount > 0 && (
        <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
          <CircleProgress size={12} color={'primary'} thickness={5} />
          <Grid item>
            <Typography variant={'body2'} color={theme.palette.primary.main}>
              <strong>{job.resultInfo.inProgressCount}</strong> {t('inProgress')}
            </Typography>
          </Grid>
        </Grid>
      )}
      {job.resultInfo.identicalCount > 0 && (
        <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            width={12}
            height={12}
            borderRadius={12}
            sx={{ backgroundColor: theme.palette.success.main }}
          >
            <IconMedium size={10} htmlColor={theme.palette.common.white} />
          </Box>
          <Grid item>
            <Typography variant={'body2'} color={theme.palette.success.main}>
              <strong>{job.resultInfo.identicalCount}</strong> {t('identical')}
            </Typography>
          </Grid>
        </Grid>
      )}
      {job.resultInfo.differentCount > 0 && (
        <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
          <Grid item>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              width={12}
              height={12}
              borderRadius={12}
              sx={{ backgroundColor: theme.palette.warning.main }}
            >
              <IconDifferent size={10} htmlColor={theme.palette.common.white} />
            </Box>
          </Grid>
          <Grid item>
            <Typography variant={'body2'} color={theme.palette.warning.main}>
              <strong>{job.resultInfo.differentCount}</strong> {t('different')}
            </Typography>
          </Grid>
        </Grid>
      )}
      {job.resultInfo.completedCount > 0 && (
        <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
          <IconCompleteCircle size={12} color={'success'} />
          <Grid item>
            <Typography variant={'body2'} color={theme.palette.success.main}>
              <strong>{job.resultInfo.completedCount}</strong> {t('completed')}
            </Typography>
          </Grid>
        </Grid>
      )}
      {job.resultInfo.failedCount > 0 && (
        <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
          <IconErrorCircle size={16} color={'error'} />
          <Grid item>
            <Typography variant={'body2'} color={theme.palette.error.main}>
              <strong>{job.resultInfo.failedCount}</strong> {t('failed')}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};
