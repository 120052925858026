import { Box, TableCell, TableRow } from '@mzc-cloudplex/core';
import { Skeleton } from '@mui/material';

const TaskItemSkeleton = () => (
  <TableRow>
    <TableCell>
      <Box sx={{ display: 'inline-flex', gap: 1 }}>
        <Skeleton variant={'circular'} width={16} height={16} />
        <Skeleton variant={'text'} width={450} height={16} />
      </Box>
    </TableCell>
    <TableCell>
      <Box sx={{ display: 'inline-flex', gap: 1 }}>
        <Skeleton variant={'circular'} width={16} height={16} />
        <Skeleton variant={'text'} width={150} height={16} />
      </Box>
    </TableCell>
    <TableCell>
      <Skeleton variant={'text'} width={50} height={16} />
    </TableCell>
    <TableCell>
      <Skeleton variant={'text'} width={150} height={16} />
    </TableCell>
  </TableRow>
);

export default TaskItemSkeleton;
