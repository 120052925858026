import React from 'react';
import {CircleProgress} from "@mzc-cloudplex/core";
import {Box} from "@mui/system";

const CircleSpinner = ({isSizeFixed = true, height }) =>
  (
    <Box
        sx={{display: `flex`, alignItems: `center`, justifyContent:`center`, height: `100%`}}
        className={`${isSizeFixed && 'loading-block'}`}
        style={height ? {height: `${height}`, minHeight: `0` } :  !isSizeFixed ? {position: 'absolute', top: '50%', left: '50%'} : undefined }>
      <CircleProgress color={'secondary'} size={40} thickness={4} />
    </Box>
  );

export default CircleSpinner;
